// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-agb-js": () => import("./../../../../../../atacama-packages/packages/addons/ecommerce-addon/src/pages/agb.js" /* webpackChunkName: "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-agb-js" */),
  "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-display-js": () => import("./../../../../../../atacama-packages/packages/addons/ecommerce-addon/src/pages/display.js" /* webpackChunkName: "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-display-js" */),
  "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-versand-und-zahlung-js": () => import("./../../../../../../atacama-packages/packages/addons/ecommerce-addon/src/pages/versand-und-zahlung.js" /* webpackChunkName: "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-versand-und-zahlung-js" */),
  "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-widerruf-js": () => import("./../../../../../../atacama-packages/packages/addons/ecommerce-addon/src/pages/widerruf.js" /* webpackChunkName: "component---atacama-packages-packages-addons-ecommerce-addon-src-pages-widerruf-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-interness-brands-addon-templates-brand-default-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/@interness/brands-addon/templates/brand/default.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-interness-brands-addon-templates-brand-default-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-interness-brands-addon-templates-brands-index-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/@interness/brands-addon/templates/brands/index.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-interness-brands-addon-templates-brands-index-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-pages-404-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/404.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-404-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-pages-contact-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/contact.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-contact-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-pages-thanks-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/thanks.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-thanks-js" */),
  "component---atacama-packages-packages-web-core-src-pages-datenschutz-social-mdx": () => import("./../../../../../../atacama-packages/packages/web-core/src/pages/datenschutz-social.mdx" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-pages-datenschutz-social-mdx" */),
  "component---atacama-packages-packages-web-core-src-pages-info-js": () => import("./../../../../../../atacama-packages/packages/web-core/src/pages/---info.js" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-pages-info-js" */),
  "component---atacama-packages-packages-web-core-src-templates-page-legal-js": () => import("./../../../../../../atacama-packages/packages/web-core/src/templates/page/legal.js" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-templates-page-legal-js" */),
  "component---src-pages-atelier-js": () => import("./../../../src/pages/atelier.js" /* webpackChunkName: "component---src-pages-atelier-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nachhaltigkeit-mdx": () => import("./../../../src/pages/nachhaltigkeit.mdx" /* webpackChunkName: "component---src-pages-nachhaltigkeit-mdx" */)
}

